import { useContext } from 'react';
import { Box, Flex } from '@tonic-ui/react';
import { SplitView } from '@web-apps/common-ui';
import CloudAssetsGraphContainer from '../components/CloudAssetsGraph/CloudAssetsGraphContainer';
import CloudAssetsContainer from '../components/CloudAssets/CloudAssetsContainer';
import ErrorBoundary from '../components/error/ErrorBoundary';
import AccountsAPIErrorOverlay from '../components/error/AccountsAPIErrorOverlay';
import GraphApiErrorOverlay from '../components/error/GraphApiErrorOverlay';
import { graphBGColorCode } from '../components/CloudAssetsGraph/helpers';
import { AppContext } from '../store/store';
import GraphHeaderControls from '../components/GraphHeaderControls';

export interface CloudAccountInfo {
  accountID: string | undefined;
  accountRegion: string | undefined;
  accountAlias: string | undefined;
  accountProvider: SvcRisksApi.Parameters.Provider | undefined;
  accountLastUpdatedTime: string | undefined;
}

const RiskExplorer = () => {
  const [{ selectedAccount, isGraphFullscreen, isRiskFocusViewActive }] = useContext(AppContext);

  return (
    <ErrorBoundary ErrorComponent={AccountsAPIErrorOverlay}>
      <Flex
        data-track="REX_splitView_fullscreenState_default"
        direction="column"
        height="100%"
        overflow="hidden"
        {...(isGraphFullscreen && {
          'data-track': 'REX_splitView_fullscreenState_expanded',
          position: 'fixed',
          inset: '0',
          background: graphBGColorCode,
        })}
      >
        <SplitView
          top={<CloudAssetsContainer />}
          bottom={
            <Box position="relative" height="100%">
              <GraphHeaderControls />
              <ErrorBoundary
                key={JSON.stringify(selectedAccount)}
                ErrorComponent={GraphApiErrorOverlay}
              >
                <CloudAssetsGraphContainer
                  activeGraphType={isRiskFocusViewActive ? 'riskView' : 'networkView'}
                />
              </ErrorBoundary>
            </Box>
          }
          isBottomFullScreen={isGraphFullscreen}
        />
      </Flex>
    </ErrorBoundary>
  );
};

export default RiskExplorer;
