import { useMemo, useState, useRef, useCallback } from 'react';
import { Text, useTheme, Tooltip, Badge } from '@tonic-ui/react';
import { pendoTrack } from '@web-apps/common-ui';
import { findRelatedEdgesByGroupId } from './linkHelpers';
import {
  getGraphConstants,
  getNodeRiskIndicatorRadiusByZoomValue,
  getNodeRiskIndicatorOffsetByZoomValue,
  minZoomLevel,
  ZoomLevel,
  graphBGColorCode,
  nodeLabelTextHeight,
} from '../helpers';
import { useCloudAssetsGraphContext } from '../useCloudAssetsGraphContext';
import CloudAssetIcon from '../../CloudAssetIcon';
import { getRiskLevel, getRiskColor, securityGroupServiceTypes } from '../../helpers';
import NodePopover from '../GraphComponents/NodePopover';

type CloudAssetsGraphNodeProps = {
  x: number;
  y: number;
  r?: number;
  text: string;
  serviceType: string;
  serviceName?: string;
  riskiestAssetScore?: number;
  highRiskAssetCount?: number;
  totalAssets?: number;
  groupId: number;
  flipGraphOrientation?: boolean;
  onGroupNodeClick?: (groupID: number | undefined) => void;
  zoomLevel: number;
  isChildren?: boolean;
  parentGroupId?: number;
  securityGroupsCount?: number;
  secondaryLinkList: SvcRisksApi.Schemas.Link[];
  primaryLinkList: SvcRisksApi.Schemas.Link[];
  securityGroupLinkList: SvcRisksApi.Schemas.Link[];
};

const CloudAssetsGraphNode = (props: CloudAssetsGraphNodeProps) => {
  const {
    x,
    y,
    text,
    r = 30,
    serviceType,
    serviceName,
    riskiestAssetScore,
    highRiskAssetCount,
    totalAssets,
    onGroupNodeClick,
    groupId,
    flipGraphOrientation,
    zoomLevel,
    isChildren = false,
    parentGroupId,
    securityGroupsCount,
    secondaryLinkList,
    primaryLinkList,
    securityGroupLinkList,
  } = props;
  const theme = useTheme();
  const { colors } = theme;

  const nodeIconWidth = r * 2 * 0.6;
  const nodeIconHeight = nodeIconWidth;

  const { nodeLabelTextWidth, nodeLabelTextPadding } = getGraphConstants(r);

  const {
    activeGroupId,
    activeSecondaryLinks,
    activePrimaryLinks,
    securityGroupNodeIsActive,
    securityGroupNodeId,
    activeSecurityGroupAssetCount,
    isolatedNodeIsActive,
    activeSGLinks,
    onActiveGroupIdChange,
    onActiveGroupParentIdChange,
    onHoveredGroupIdChange,
    onActiveSecondaryLinksChange,
    onActivePrimaryLinksChange,
    onActiveSGLinksChange,
    onSecurityGroupNodeIsActiveChange,
    onActiveSecurityGroupAssetCountChange,
    onIsolatedNodeIsActiveChange,
    activeGroupParentId,
  } = useCloudAssetsGraphContext();
  const [isHover, setIsHover] = useState(false);
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const isSGNode = securityGroupServiceTypes.includes(serviceType);

  const isActive = activeGroupId === groupId || (isSGNode && securityGroupNodeIsActive);

  const totalAssetCount =
    activeSecurityGroupAssetCount && isSGNode ? activeSecurityGroupAssetCount : totalAssets;

  const isConnected = useMemo(() => {
    if (!activeGroupId) return true;

    const isConnectedViaPrimaryLink = activePrimaryLinks?.some((link: SvcRisksApi.Schemas.Link) => {
      const isEdgeTargetMatch = link.targetGroupID === (parentGroupId || groupId);
      const isEdgeSourceMatch = link.sourceGroupID === (parentGroupId || groupId);
      const isLinkDuplicateOfActiveSGLink = !!(
        link.targetGroupID === activeGroupParentId &&
        activeSGLinks?.some((activeSGLink) => activeSGLink.targetGroupID === activeGroupId)
      );

      return !isLinkDuplicateOfActiveSGLink && (isEdgeTargetMatch || isEdgeSourceMatch);
    });

    const isConnectedViaSecondaryLink = activeSecondaryLinks?.some(
      (link: SvcRisksApi.Schemas.Link) => {
        const isEdgeTargetMatch = link.targetGroupID === groupId;
        const isEdgeSourceMatch = link.sourceGroupID === groupId;

        return isEdgeTargetMatch || isEdgeSourceMatch;
      }
    );

    const isConnectedViaSecurityGroupLink = activeSGLinks?.some(
      (link: SvcRisksApi.Schemas.Link) => {
        const isEdgeTargetMatch = link.targetGroupID === groupId;
        const isEdgeSourceMatch = link.sourceGroupID === groupId;
        const hasEndTargetGroupIDs = !!link.endTargetGroupIDs?.length;
        const isEndTargetGroupActive = link.endTargetGroupIDs?.includes(activeGroupId);
        const isSourceGroupActive = link.sourceGroupID === activeGroupId;

        const isEndTargetGroupConditionMet = hasEndTargetGroupIDs && isEndTargetGroupActive;
        const isSourceGroupConditionMet = !hasEndTargetGroupIDs && isSourceGroupActive;

        return (
          (isEndTargetGroupConditionMet || isSourceGroupConditionMet) &&
          (isEdgeTargetMatch || isEdgeSourceMatch)
        );
      }
    );

    return (
      isConnectedViaPrimaryLink || isConnectedViaSecondaryLink || isConnectedViaSecurityGroupLink
    );
  }, [
    groupId,
    activeGroupId,
    activePrimaryLinks,
    activeSecondaryLinks,
    activeSGLinks,
    parentGroupId,
    activeGroupParentId,
  ]);

  const nodeOpacity = isActive || isConnected || isolatedNodeIsActive ? '1' : '0.4';

  const hoverTimer = useRef<number>();

  const nodeTruncatedName = useMemo(() => {
    if (text.length > 16) {
      return text.slice(0, 7) + '...' + text.slice(text.length - 1 - 6, text.length);
    } else {
      return text;
    }
  }, [text]);

  const nodeElement = useMemo(() => {
    if (serviceType === 'Internet') {
      return (
        <circle
          cx={x}
          cy={y}
          r={r}
          fill={colors[graphBGColorCode]}
          stroke={colors['gray:40']}
          strokeDasharray={4}
          strokeWidth="2"
        />
      );
    }
    return (
      <g>
        {!!riskiestAssetScore && riskiestAssetScore > 70 && (
          <circle cx={x} cy={y} r={r} stroke={colors['red:50']} opacity="0.3" strokeWidth="17" />
        )}
        <circle
          cx={x}
          cy={y}
          r={r}
          fill={!isActive ? colors[graphBGColorCode] : colors['blue:60']}
          stroke={
            isHover && isActive
              ? colors['blue:40']
              : isHover
                ? colors['blue:50']
                : isActive
                  ? colors['blue:60']
                  : colors['gray:40']
          }
          strokeWidth="2"
        />
      </g>
    );
  }, [serviceType, riskiestAssetScore, x, y, r, isActive, colors, isHover]);

  const changeIsolatedNodeActiveStatus = useCallback(() => {
    const isInPrimaryLinkList = primaryLinkList?.some(
      (link) =>
        link.sourceGroupID === (parentGroupId || groupId) ||
        link.targetGroupID === (parentGroupId || groupId)
    );

    const isInSecondaryLinkList = secondaryLinkList?.some(
      (link) =>
        link.sourceGroupID === (parentGroupId || groupId) ||
        link.targetGroupID === (parentGroupId || groupId)
    );

    const isInSecurityGroupLinkList = securityGroupLinkList?.some(
      (link) =>
        link.sourceGroupID === (parentGroupId || groupId) ||
        link.targetGroupID === (parentGroupId || groupId)
    );

    onIsolatedNodeIsActiveChange(
      !isInPrimaryLinkList && !isInSecondaryLinkList && !isInSecurityGroupLinkList
    );
  }, [
    onIsolatedNodeIsActiveChange,
    parentGroupId,
    groupId,
    primaryLinkList,
    secondaryLinkList,
    securityGroupLinkList,
  ]);

  const updateRelatedActiveLinks = useCallback(
    (groupId: number, parentGroupId?: number) => {
      const updateLinks = (
        groupId: number,
        linkList: any[],
        updateLinkFn: (linkList: any[]) => void
      ) => {
        const activeLinkList = findRelatedEdgesByGroupId(groupId, linkList);
        updateLinkFn(activeLinkList || []);
      };

      // update active primary edges
      updateLinks(parentGroupId || groupId, primaryLinkList, onActivePrimaryLinksChange);
      // update active secondary edges
      updateLinks(groupId, secondaryLinkList, onActiveSecondaryLinksChange);

      if (securityGroupNodeId) {
        // update active SG edges
        updateLinks(groupId, securityGroupLinkList, onActiveSGLinksChange);
      }
    },
    [
      onActivePrimaryLinksChange,
      onActiveSecondaryLinksChange,
      onActiveSGLinksChange,
      securityGroupNodeId,
      primaryLinkList,
      secondaryLinkList,
      securityGroupLinkList,
    ]
  );

  return (
    <g
      data-id={`graph-node-${groupId}`}
      onMouseEnter={() => {
        setIsHover(true);
        if (groupId !== 0) setIsTooltipOpen(true);
        window.clearTimeout(hoverTimer.current);
        hoverTimer.current = window.setTimeout(() => {
          if (parentGroupId) {
            onHoveredGroupIdChange(parentGroupId);
          } else {
            onHoveredGroupIdChange(groupId);
          }
        }, 250);
      }}
      onMouseLeave={() => {
        setIsHover(false);
        setIsTooltipOpen(false);
        window.clearTimeout(hoverTimer.current);
        onHoveredGroupIdChange(undefined);
      }}
      onClick={() => {
        if (groupId) {
          if (groupId === activeGroupId) {
            onGroupNodeClick?.(undefined);
            onActiveGroupParentIdChange(undefined);
            onActiveGroupIdChange(undefined);
            onActiveSecurityGroupAssetCountChange(undefined);
            onSecurityGroupNodeIsActiveChange(false);
            onActiveSecondaryLinksChange([]);
            onActivePrimaryLinksChange([]);
            onActiveSGLinksChange([]);
          } else {
            onGroupNodeClick?.(groupId);
            onActiveGroupParentIdChange(parentGroupId || undefined);
            onActiveGroupIdChange(groupId);
            updateRelatedActiveLinks(groupId, parentGroupId);
            onSecurityGroupNodeIsActiveChange(!!securityGroupsCount);

            if (securityGroupsCount || isSGNode) {
              onActiveSecurityGroupAssetCountChange(securityGroupsCount);
            } else {
              onSecurityGroupNodeIsActiveChange(false);
              onActiveSecurityGroupAssetCountChange(0);
            }
            pendoTrack(`REX_cloudAssetsGraphNode_click`, {
              assetType: serviceType,
              assetCount: totalAssets,
              riskScore: riskiestAssetScore,
              zoomLevel,
            });
          }
        }
        changeIsolatedNodeActiveStatus();
      }}
      style={{ cursor: 'pointer' }}
      opacity={nodeOpacity}
    >
      <NodePopover
        isShown={isTooltipOpen}
        label={text}
        serviceName={serviceName}
        serviceType={serviceType}
        riskiestAssetScore={riskiestAssetScore}
        highRiskAssetCount={highRiskAssetCount}
      >
        {nodeElement}
      </NodePopover>
      {(minZoomLevel(ZoomLevel.Wide, zoomLevel) || serviceType === 'Internet') && (
        <CloudAssetIcon
          serviceType={serviceType}
          serviceName={serviceName}
          width={nodeIconWidth}
          height={nodeIconHeight}
          x={x - nodeIconWidth / 2}
          y={y - nodeIconHeight / 2}
          fill={
            !isActive && !isHover
              ? colors['white:secondary']
              : !isActive && isHover
                ? colors['blue:50']
                : colors['white:emphasis']
          }
        />
      )}

      {!!riskiestAssetScore &&
        (getRiskLevel(riskiestAssetScore) === 'high' ||
          getRiskLevel(riskiestAssetScore) === 'medium') && (
          <circle
            cx={getNodeRiskIndicatorOffsetByZoomValue(zoomLevel, x)}
            cy={getNodeRiskIndicatorOffsetByZoomValue(zoomLevel, y)}
            r={getNodeRiskIndicatorRadiusByZoomValue(zoomLevel, r)}
            fill={colors[getRiskColor(riskiestAssetScore)]}
            stroke={colors[graphBGColorCode]}
            strokeWidth="2"
          />
        )}

      {!!text && minZoomLevel(ZoomLevel.Wide, zoomLevel) && (
        <foreignObject
          x={x - r - nodeLabelTextPadding}
          y={
            flipGraphOrientation && serviceType === 'Internet'
              ? y - r - nodeLabelTextHeight - 2
              : y + r + 2
          }
          width={nodeLabelTextWidth + nodeLabelTextPadding}
          height={nodeLabelTextHeight}
        >
          <Tooltip
            disabled={text === nodeTruncatedName}
            label={text}
            PopperProps={{ usePortal: true }}
            background="gray:80"
            color={colors['white:secondary']}
          >
            <Text
              color={colors['white:secondary']}
              fontSize="sm"
              lineHeight="sm"
              fontWeight="normal"
              textAlign="center"
              textTransform="capitalize"
              background={
                serviceType !== 'Internet' && !isChildren ? colors[graphBGColorCode] : undefined
              }
            >
              {nodeTruncatedName}
            </Text>
          </Tooltip>
        </foreignObject>
      )}
      {!!totalAssetCount && minZoomLevel(ZoomLevel.Medium, zoomLevel) && (
        <foreignObject width="40px" height="40px" x={x + r - 14} y={y - r}>
          <Badge
            backgroundColor="gray:90"
            borderColor="gray:40"
            borderStyle="solid"
            borderWidth="1px"
            color="white:emphasis"
            fontSize="xs"
            badgeContent={totalAssetCount > 999 ? '999+' : totalAssetCount}
          />
        </foreignObject>
      )}
    </g>
  );
};

CloudAssetsGraphNode.displayName = 'CloudAssetsGraphNode';
export default CloudAssetsGraphNode;
