import { useCallback, useContext, useEffect, useMemo } from 'react';
import {
  Box,
  Space,
  Flex,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuToggle,
  MenuToggleIcon,
  Text,
  TextLabel,
  Tooltip,
  Truncate,
  useColorMode,
  Scrollbar,
  useColorStyle,
  Divider,
  Spinner,
  Icon,
} from '@tonic-ui/react';
import { encodeDataTrackContent } from '@web-apps/common-ui';
import { InfoOIcon } from '@tonic-ui/react-icons';
import { Trans } from 'react-i18next';
import TruncateWithTooltip from './TruncateWithTooltip';
import {
  requiredProvidersServiceNamesForGraph,
  graphBGColorCode,
} from './CloudAssetsGraph/helpers';
import RiskScoreIndicator from './RiskScoreIndicator';
import { API } from '../services';
import { AppContext } from '../store/store';
import { regionNameMapping } from '../provider-regions/utils';

const getProviderListAssets = (cloudProvider: SvcRisksApi.Parameters.Provider | undefined) => {
  if (!cloudProvider) {
    return [];
  }
  return requiredProvidersServiceNamesForGraph[cloudProvider];
};

const CloudAccountSelectionControl = () => {
  const [
    { availableProviders = [], selectedAccount, selectedProvider, isGraphFullscreen },
    dispatchGlobalStoreEvent,
  ] = useContext(AppContext);

  const { data: cloudProvidersData, isLoading: cloudProviderDataIsLoading } =
    API.useGetListCloudProviders({ throwOnError: true });

  const { isLoading: isAccountDetailsLoading, data: accountDetailsData } = API.useGetAccountDetails(
    {
      provider: selectedAccount?.accountProvider,
      accountID: selectedAccount?.accountID,
    }
  );

  const [colorMode] = useColorMode();
  const [colorStyle] = useColorStyle({ colorMode });

  const availableProvidersKeys = availableProviders.map(
    (availableProvider) => availableProvider.cloudProvider
  ) as SvcRisksApi.Parameters.Provider[];

  const { isLoading: isCloudAccountDetailsLoading, data: cloudAccountData } =
    API.useGetListAccounts(
      {
        availableProviders: availableProvidersKeys,
        provider: 'All',
      },
      { throwOnError: true }
    );

  const currentCloudAccount =
    cloudAccountData?.data?.find(
      (cloudAccount) => selectedAccount?.accountID === cloudAccount.accountID
    ) ?? cloudAccountData?.data?.[0];

  const handleAccountChange = useCallback(
    (selectedAccountID: string) => {
      const selectedAccount = cloudAccountData?.data?.find(
        (cloudAccount) => cloudAccount.accountID === selectedAccountID && cloudAccount.regions
      );

      if (selectedAccount) {
        dispatchGlobalStoreEvent({
          type: 'CHANGE_ACCOUNT',
          payload: {
            accountProvider: selectedAccount.provider,
            accountID: selectedAccount.accountID,
            accountAlias: selectedAccount.accountAlias,
            accountRegion: selectedAccount.regions[0],
            accountLastUpdatedTime: undefined,
          },
        });
      }
    },
    [cloudAccountData, dispatchGlobalStoreEvent]
  );

  const handleRegionChange = (region: string) => {
    dispatchGlobalStoreEvent({
      type: 'CHANGE_ACCOUNT_REGION',
      payload: region,
    });
  };

  const handleProviderChange = (provider: SvcRisksApi.Parameters.Provider) => {
    if (provider !== selectedProvider) {
      dispatchGlobalStoreEvent({
        type: 'CHANGE_CLOUD_PROVIDER',
        payload: provider,
      });
    }
  };

  const requiredServiceNamesForGraph = getProviderListAssets(selectedAccount?.accountProvider);

  const RequiredResourceTypesTooltipContent = () => {
    return (
      <Box width="500px">
        <Text color="white:emphasis" marginBottom="2x" marginTop="2x">
          <Trans i18nKey="riskGraph.empty.regionsNotVisibleInfo" />
        </Text>
        <Divider backgroundColor="gray:70" />
        <Box as="ul" paddingLeft="6x" display="flex" flexWrap="wrap">
          {requiredServiceNamesForGraph?.map((serviceName) => {
            return (
              <Box
                key={serviceName}
                as="li"
                display="list-item"
                color="white:primary"
                marginBottom="2x"
                flexBasis="50%"
                boxSizing="border-box"
              >
                <Text>{serviceName}</Text>
              </Box>
            );
          })}
        </Box>
      </Box>
    );
  };

  const regionsList = accountDetailsData
    ? accountDetailsData.data.regions
    : currentCloudAccount
      ? currentCloudAccount.regions
      : [];

  const getFilteredCloudAccountData = useMemo(() => {
    if (selectedProvider === 'All') {
      return cloudAccountData?.data;
    }
    return cloudAccountData?.data?.filter((account) => account.provider === selectedProvider);
  }, [selectedProvider, cloudAccountData]);

  const sortedCloudAccountDataWithSelectedProvider = useMemo(() => {
    return getFilteredCloudAccountData.sort((a, b) => b.highestScore - a.highestScore);
  }, [getFilteredCloudAccountData]);

  const defaultAccountIndex = useMemo(() => {
    return (
      sortedCloudAccountDataWithSelectedProvider?.findIndex(
        (cloudAccount) => cloudAccount.accountID === selectedAccount?.accountID
      ) ?? 0
    ); // not found? fallback to the first account
  }, [sortedCloudAccountDataWithSelectedProvider, selectedAccount?.accountID]);

  const defaultRegionIndex =
    regionsList?.findIndex((region) => {
      if (typeof region === 'string') {
        return region === selectedAccount?.accountRegion;
      } else {
        return region.region === selectedAccount?.accountRegion;
      }
    }) ?? 0; // not found? fallback to the first region

  useEffect(() => {
    if (regionsList.length && !selectedAccount?.accountRegion) {
      handleRegionChange(
        typeof regionsList[0] === 'string' ? regionsList[0] : regionsList[0].region
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [regionsList, selectedAccount]);

  const defaultProviderIndex =
    cloudProvidersData?.data?.findIndex((provider) => {
      return provider.cloudProvider === selectedProvider;
    }) ?? 0; // not found? fallback to the first provider

  return (
    <Box position="absolute" left="4x" top="4x">
      <Flex direction="row">
        {isGraphFullscreen && (
          <Flex direction="row" paddingRight="1x">
            <Menu
              display="block"
              data-id="provider-dropdown"
              defaultActiveIndex={defaultProviderIndex}
              marginRight="1x"
            >
              <MenuButton
                data-track="REX_provider_selector"
                data-track-content={encodeDataTrackContent({
                  provider: selectedProvider,
                })}
                variant="secondary"
                overflow="hidden"
                background="gray:100"
              >
                <Flex>
                  <TextLabel marginRight="2x">
                    <Trans i18nKey="cloudProvider" />:
                  </TextLabel>
                  <Text overflow="hidden" textOverflow="ellipsis" width="40px" textAlign="left">
                    {selectedProvider}
                  </Text>
                </Flex>
              </MenuButton>
              <MenuList width="100%">
                <Scrollbar height="auto" maxHeight="200px" overflowY="visible">
                  {cloudProviderDataIsLoading ? (
                    <Flex height="100%" justifyContent="center" alignItems="center">
                      <Spinner />
                    </Flex>
                  ) : (
                    cloudProvidersData?.data?.map((provider) => (
                      <MenuItem
                        key={provider.cloudProvider}
                        onClick={() => {
                          if (provider.cloudProvider) {
                            handleProviderChange(
                              provider.cloudProvider as SvcRisksApi.Parameters.Provider
                            );
                          }
                        }}
                        {...(provider.cloudProvider === selectedProvider && {
                          backgroundColor: (colorStyle.background as any).selected,
                        })}
                      >
                        <Flex minWidth="0" align="center">
                          <Text>{provider.cloudProvider}</Text>
                        </Flex>
                      </MenuItem>
                    ))
                  )}
                </Scrollbar>
              </MenuList>
            </Menu>
            <Menu
              display="block"
              data-id="account-dropdown"
              defaultActiveIndex={defaultAccountIndex}
            >
              <MenuButton
                data-track="REX_accounts_selector"
                data-track-content={encodeDataTrackContent({
                  provider: selectedAccount?.accountProvider,
                })}
                variant="secondary"
                overflow="hidden"
                background="gray:100"
              >
                <Flex>
                  <TextLabel marginRight="2x">
                    <Trans i18nKey="account" />:
                  </TextLabel>
                  <Text overflow="hidden" textOverflow="ellipsis" width="180px" textAlign="left">
                    {`${selectedAccount?.accountAlias || ''} ${
                      selectedProvider === 'All' && selectedAccount?.accountProvider
                        ? `(${selectedAccount.accountProvider})`
                        : ''
                    }`}
                  </Text>
                </Flex>
              </MenuButton>
              <MenuList width="100%">
                <Scrollbar height="auto" maxHeight="200px" overflowY="visible">
                  {isCloudAccountDetailsLoading ? (
                    <Flex height="100%" justifyContent="center" alignItems="center">
                      <Spinner />
                    </Flex>
                  ) : (
                    sortedCloudAccountDataWithSelectedProvider?.map((account) => (
                      <MenuItem
                        key={account.accountID}
                        onClick={() => handleAccountChange(account.accountID)}
                        {...(account.accountID === currentCloudAccount?.accountID && {
                          backgroundColor: (colorStyle.background as any).selected,
                        })}
                      >
                        <Flex minWidth="0" align="center">
                          <RiskScoreIndicator riskScore={account.highestScore} />
                          <TruncateWithTooltip
                            text={`${account.accountAlias || ''} ${
                              selectedProvider === 'All' && account.provider
                                ? `(${account.provider})`
                                : ''
                            }`}
                            tooltipStyles={{
                              PopperProps: { usePortal: true },
                              placement: 'right',
                            }}
                          />
                        </Flex>
                      </MenuItem>
                    ))
                  )}
                </Scrollbar>
              </MenuList>
            </Menu>
          </Flex>
        )}
        <Flex direction="column">
          <Menu
            display="block"
            data-id="region-dropdown"
            width="300px"
            defaultActiveIndex={defaultRegionIndex}
          >
            <MenuToggle
              data-track="REX_accountRegions_selector"
              data-track-content={encodeDataTrackContent({
                provider: selectedAccount?.accountProvider,
              })}
              border="1px solid"
              borderColor="gray:60"
              borderRadius="sm"
              textAlign="left"
              width="100%"
              paddingX="2x"
              paddingY="7px"
              flex="1"
              background="gray:100"
              {...(isAccountDetailsLoading && { pointerEvents: 'none' })}
            >
              <TextLabel marginRight="2x">
                <Trans i18nKey="region" />:
              </TextLabel>
              <Truncate color="white:primary" flex="1">
                {isAccountDetailsLoading
                  ? null
                  : regionNameMapping(selectedProvider, selectedAccount?.accountRegion)}
              </Truncate>
              <Space width="20x" />
              {isAccountDetailsLoading ? (
                <Spinner size="xs" />
              ) : (
                <MenuToggleIcon color="white:primary" />
              )}
            </MenuToggle>
            <MenuList width="300px">
              <Scrollbar height="auto" maxHeight="200px" overflowY="visible">
                {regionsList?.map((accountRegion) => {
                  const highestScore =
                    typeof accountRegion === 'string' ? undefined : accountRegion.highestScore;
                  const region =
                    typeof accountRegion === 'string' ? accountRegion : accountRegion.region;
                  return (
                    <MenuItem
                      key={region}
                      onClick={() => handleRegionChange(region)}
                      {...(selectedAccount?.accountRegion === region && {
                        backgroundColor: (colorStyle.background as any).selected,
                      })}
                    >
                      <Flex minWidth="0" align="center">
                        {highestScore !== undefined && (
                          <RiskScoreIndicator riskScore={highestScore} />
                        )}
                        <TruncateWithTooltip
                          text={regionNameMapping(selectedProvider, region)}
                          tooltipStyles={{
                            PopperProps: { usePortal: true },
                            placement: 'right',
                          }}
                        />
                      </Flex>
                    </MenuItem>
                  );
                })}
              </Scrollbar>
            </MenuList>
          </Menu>
        </Flex>
        <Flex alignSelf="center">
          <Tooltip
            label={<RequiredResourceTypesTooltipContent />}
            placement="bottom"
            backgroundColor="gray:80"
            color="white:primary"
          >
            <Icon
              as={InfoOIcon}
              tabIndex={0}
              borderRadius="100%"
              width="4x"
              height="4x"
              background={graphBGColorCode}
              color="gray:10"
              marginLeft="2x"
            />
          </Tooltip>
        </Flex>
      </Flex>
    </Box>
  );
};
CloudAccountSelectionControl.displayName = 'CloudAccountSelectionControl';

export default CloudAccountSelectionControl;
